import React , { useEffect }from 'react';
import TopBar from './TopBar'; // Importing the Topbar component

const ProductPage = () => {

    // Scroll to the top when the page loads
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div className="min-h-screen flex flex-col">
      {/* Topbar Component */}
      <TopBar />

      {/* Main Product Content */}
      <main className="flex-grow max-w-3xl mx-auto p-6">
        {/* Product Information */}
        <h1 className="text-3xl font-bold mb-4 text-emerald-800">Our Product Brochure</h1>
        <p className="text-gray-700 mb-8">
        At Earthify, we create sustainable disposable products designed to make a positive impact on the environment. 
        Our goal is to reduce waste and provide eco-friendly alternatives that help protect the planet. 
        Here is our brochure for you to explore, and you can download it to learn more about what we stand for.
           </p>

    
        {/* Option 1: Link to the Brochure PDF */}
        <div className="mt-4 text-center">
          <a
            href="/assets/FEPL PRINT.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-emerald-600 text-white px-4 py-2 rounded-lg hover:bg-emerald-700"
          >
            View Brochure
          </a>
        </div>

        {/* Option 2: Embed the Brochure PDF */}
        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4 text-emerald-800">Brochure</h2>
          <iframe
            src="/assets/FEPL PRINT.pdf"
            width="100%"
            height="500px"
            className="border rounded-lg"
            title="Product Brochure"
          ></iframe>
        </div>
      </main>
{/* Footer */}
<footer className="bg-green-800 text-white p-4">
                <div className="container mx-auto text-center">
                    <p>&copy; 2024 ThEarthify. All rights reserved.</p>
                    <p>Together, let's make a greener future!</p>
                </div>
            </footer>
    </div>
  );
};

export default ProductPage;
