import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Send } from 'lucide-react';
import emailjs from 'emailjs-com';
import TopBar from './TopBar'; // Import the TopBar component

const ProductInquiryForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    companyName: '',
    description: ''
  });
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });

    emailjs.send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        companyName: formData.companyName, 
        description: formData.description
      },
      process.env.REACT_APP_PUBLIC_KEY
    ).then((result) => {
      if (result.status === 200) {
        setShowNotification(true);
        setFormData({ name: '', email: '', phone: '', companyName: '', description: '' });
        setTimeout(() => {
          setShowNotification(false);
          navigate('/'); // Redirect to home page
        }, 3000);
      }
    }, (error) => {
      console.error('Error sending email:', error.text);
      alert('Failed to send inquiry. Please try again later.');
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const notificationStyles = {
    position: 'fixed',
    top: '1rem',
    right: showNotification ? '1rem' : '-100%',
    backgroundColor: '#2196f3', 
    color: 'white',
    padding: '1rem',
    borderRadius: '0.5rem',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'right 0.5s ease-in-out',
    width: '50%',
  };

  return (
    <div>
      {/* Header */}
      <TopBar />

      <div className="min-h-screen bg-emerald-50 py-12 px-4">
        <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-xl p-8">
          <h2 className="text-3xl font-bold text-emerald-800 mb-6 text-center">Product Inquiry</h2>

          {/* Sliding Notification */}
          {showNotification && (
            <div style={notificationStyles}>
              <span>Thank you for your inquiry! We will get back to you shortly.</span>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-emerald-700 mb-1">
                Name *
              </label>
              <input
                type="text"
                id="name"
                name="name"
                required
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-emerald-300 rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-emerald-700 mb-1">
                Email *
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-emerald-300 rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
              />
            </div>

            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-emerald-700 mb-1">
                Phone Number *
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                required
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-emerald-300 rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
              />
            </div>

            <div>
              <label htmlFor="companyName" className="block text-sm font-medium text-emerald-700 mb-1">
                Company Name *
              </label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                required
                value={formData.companyName}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-emerald-300 rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
              />
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-emerald-700 mb-1">
                Description of Products/Services Required *
              </label>
              <textarea
                id="description"
                name="description"
                required
                value={formData.description}
                onChange={handleChange}
                rows="4"
                className="w-full px-4 py-2 border border-emerald-300 rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-md flex items-center justify-center gap-2 transition duration-200"
            >
              Submit Inquiry
              <Send className="w-4 h-4" />
            </button>
          </form>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-green-800 text-white p-4">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 ThEarthify. All rights reserved.</p>
          <p>Together, let's make a greener future!</p>
        </div>
      </footer>
    </div>
  );
};

export default ProductInquiryForm;
